import { useFetcher } from '@remix-run/react';
import { useState } from 'react';
import { Button } from './ui/button';
import { Combobox } from './ui/combobox';

export type RequestListProps = {
  options: { value: string; label: string }[];
  title: string;
  placeholder: string;
  submittedTitle: string;
  submittedMessage: string;
  ctaButton: {
    label: string;
    href: string;
  };
};

export function RequestList({
  options,
  title,
  placeholder,
  submittedTitle,
  submittedMessage,
  ctaButton,
}: RequestListProps) {
  const fetcher = useFetcher({ key: 'hubspot-lead' });
  const [value, setValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.set('searchValue', value);
    fetcher.submit(formData, { method: 'POST' });
    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  return (
    <div className="p-5 rounded-xl bg-primary">
      {isSubmitted ? (
        <>
          <p className="mb-5 text-xl font-bold">{submittedTitle}</p>
          <p>{submittedMessage}</p>
        </>
      ) : (
        <fetcher.Form>
          <p className="mb-5 text-xl font-bold">{title}</p>
          <div className="relative">
            <Combobox
              value={value}
              setValue={setValue}
              options={options}
              placeholder={placeholder}
              triggerClassName="rounded-lg text-card bg-white w-full justify-normal hover:bg-white hover:text-card"
              commandClassName="text-card bg-white"
              commandItemClassName="aria-selected:bg-primary text-card"
              hideIcon
            />
            <Button
              variant="link"
              className="absolute right-2.5 top-1/2 -translate-y-1/2 transform   font-medium placeholder:text-black hover:no-underline"
              onClick={handleSubmit}
              disabled={!value || isSubmitting}
            >
              {ctaButton.label}
            </Button>
          </div>
        </fetcher.Form>
      )}
    </div>
  );
}
